<template>
  <card-component :title="title" class="no-result-card">
    <component :is="helpComponent" />
    <b-message type="is-info"><slot></slot></b-message>
  </card-component>
</template>

<script>
import allAnalyses from '../../../data/analysis.json'
import { useMenuStore } from '@/stores/menu'

export default {
  name: 'CardNoResult',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue')
  },
  data: function () {
    return {
      analyses: allAnalyses
    }
  },
  setup () {
    const menuStore = useMenuStore()
    return { menuStore }
  },
  computed: {
    title: function () {
      return 'Purpose of ' + this.analyse.title + ':'
    },
    analyse: function () {
      return this.menuStore.currentAnalysis
    },
    helpComponent: function () {
      let helpComponent = null
      if (this.analyse) {
        try {
          require('@/components/help/firstlevel/' + this.analyse.wfclass + '.vue')
          helpComponent = () => import('@/components/help/firstlevel/' + this.analyse.wfclass + '.vue')
        } catch (e) {
          helpComponent = null
        }
      } else {
        helpComponent = null
      }
      return helpComponent
    }
  }
}
</script>
<style scoped>
.no-result-card {
  min-height: 30rem;
}
ul {
  list-style: inside;
  text-align: left;
  word-wrap: break-word;
}
ul ul {
  list-style: square;
  margin-left: 30px;
}
ul > li {
  margin-top: 10px;
}
.message {
  margin-top:30px;
}
</style>
